import { formatButton, formatGradient, formatPriceRef } from './elements'

export const formatPanelPair = panel => ({
  id: panel.sys.id,
  type: panel.sys.contentType.sys.id,
  title: panel.fields.title,
  subtitle: panel.fields.subtitle,
  multilineSubtitle: panel.fields.multiLineSubtitle,
  textSize: panel.fields.textSize,
  image: panel.fields.image,
  gradient: panel.fields.gradient && formatGradient(panel.fields.gradient),
  keyValuePair: panel.fields.keyValuePair,
  darkText: panel.fields.darkText,
  buttons:
    panel.fields.buttons &&
    panel.fields.buttons.map(button => formatButton(button)),
})

export const formatParallaxPanel = panel => ({
  id: panel.sys.id,
  type: panel.sys.contentType.sys.id,
  title: panel.fields.title,
  description: panel.fields.description,
  image: panel.fields.image,
  body: panel.fields.body,
  disclaimer: panel.fields.disclaimer,
  gradientKey: panel.fields.gradientKey,
  primaryCtaUrl: panel.fields.primaryCtaUrl,
  primaryCtaTitle: panel.fields.primaryCtaTitle,
  secondaryCtaUrl: panel.fields.secondaryCtaUrl,
  secondaryCtaTitle: panel.fields.secondaryCtaTitle,
  priceRef: panel.fields.priceRef && formatPriceRef(panel.fields.priceRef),
  priceFormat: panel.fields.priceFormat,
  price: panel.fields.price,
})
