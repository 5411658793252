import styled, { css } from 'styled-components'

import { gradients } from '../../constants/gradients'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Container } from '../../units/Container'
import { between } from '../../utils/between'
import { media, mediaMax } from '../../utils/media'
import { mixins } from '../../utils/mixins'

type TextContentProps = {
  textContentSide?: any
}

type SingleImageProps = {
  textContentSide?: any
}

type ThreeImagesContainerProps = {
  textContentSide?: any
}

type ImagePairProps = {
  textContentSide?: any
}

type TwoImagesContentProps = {
  textContentSide?: any
  color1?: any
  color2?: any
}

type TwoImagesContainerProps = {
  textContentSide?: any
}

type ImageContentProps = {
  textContentSide?: any
  color1?: any
  color2?: any
}

type ThreeImagesContentProps = {
  color1?: any
  color2?: any
}

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${media.md(css`
    flex-wrap: nowrap;
    flex-direction: row;
  `)}
`

export const TextContent = styled.div<TextContentProps>`
  flex: 0 0 100%;
  margin-bottom: 32px;
  padding-top: 0;
  animation: 3000ms ease-in-out 0s 1 textSlideInFromBottomMobile;

  @keyframes textSlideInFromBottomMobile {
    0% {
      opacity: 0;
      transform: translateY(16px);
    }
    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  ${media.md(css`
    flex: 1;
    margin-bottom: 0;
    animation: 3000ms ease-in-out 0s 1 textSlideInFromBottom;

    @keyframes textSlideInFromBottom {
      0% {
        opacity: 0;
        transform: translateY(96px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${({ textContentSide }: TextContentProps) =>
      textContentSide === 'right'
        ? css`
            padding-left: ${({ theme }) => theme.spacing[4]};
          `
        : css`
            padding-right: ${({ theme }) => theme.spacing[4]};
          `}
  `)};

  ${media.mlg(css`
    padding-top: ${({ theme }) => theme.spacing[4]};
  `)};
`

export const Column = styled.div`
  padding: ${({ theme }) => `${theme.spacing[2.5]} 0`};
  width: 100%;
  z-index: ${zIndex.above2};

  ${media.md(css`
    display: flex;
    align-items: center;
    width: 50%;
    padding-top: ${({ theme }) => theme.spacing[3.5]};
  `)};
`

export const ImageContent = styled.div<ImageContentProps>`
  position: relative;
  flex: 0 0 100%;
  min-height: 288px;
  z-index: ${zIndex.above};

  ${media.md(css`
    flex: 0 1 50%;
    min-height: ${between(480 / (modularScale * 2), 480)};
    align-self: flex-start;
    ${({ textContentSide }: ImageContentProps) =>
      textContentSide === 'right' &&
      css`
        order: -1;
      `}
  `)};

  &::after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    width: 76%;
    height: 185%;
    animation: 3000ms ease-in-out 0s 1 contentSlideInFromBottom;

    .t-dark & {
      opacity: 0.1;
    }

    ${({ textContentSide }) =>
      textContentSide === 'right'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}

    ${media.md(css`
      height: 135%;
      width: 73%;

      ${({ textContentSide }: ImageContentProps) =>
        textContentSide === 'right'
          ? css`
              left: ${({ theme }) => theme.spacing[1.5]};
            `
          : css`
              right: ${({ theme }) => theme.spacing[1.5]};
            `}
    `)};

    ${props => css`
      background: linear-gradient(
        ${props.color1 || gradients.supportSilver[0]},
        ${props.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

export const SingleImage = styled.div<SingleImageProps>`
  position: absolute;
  z-index: ${zIndex.above};
  top: ${({ theme }) => theme.spacing[2.5]};
  margin-left: ${({ theme }) => theme.spacing[2]};
  width: 89%;
  height: 91%;
  animation: 3000ms ease-in-out 0s 1 singleImageSlideInFromTop;

  @keyframes singleImageSlideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  ${media.md(css`
    top: ${({ theme }) => theme.spacing[1.5]};
    margin-left: 0;
    width: 86%;
    height: 102%;

    ${({ textContentSide }: SingleImageProps) =>
      textContentSide === 'right'
        ? css`
            left: ${({ theme }) => theme.spacing[4]};
          `
        : css`
            right: ${({ theme }) => theme.spacing[4]};
          `}
  `)};

  ${media.mlg(css`
    top: ${({ theme }) => theme.spacing[4.5]};
  `)};
`

export const TwoImagesContainer = styled.div<TwoImagesContainerProps>`
  flex: 0 1 100%;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  position: relative;

  ${mediaMax.md(css`
    ${mixins.breakFromSiteGutter()}
  `)}

  ${media.md(css`
    flex: 0 1 60%;
    margin-top: 0;
    margin-bottom: 0;

    ${({ textContentSide }: TwoImagesContainerProps) =>
      textContentSide === 'right'
        ? css`
            margin-left: 0;
            order: -1;
          `
        : css`
            margin-right: 0;
          `}
  `)};
`

export const TwoImagesContent = styled.div<TwoImagesContentProps>`
  position: relative;
  flex: 0 0 100%;
  min-height: 288px;
  z-index: ${zIndex.above};

  ${media.md(css`
    flex: 0 1 45%;
    min-height: ${between(480 / (modularScale * 2), 480)};
    align-self: flex-start;
  `)};

  &::after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    width: 63%;
    height: 192%;
    animation: 3000ms ease-in-out 0s 1 contentSlideInFromBottom;

    .t-dark & {
      opacity: 0.1;
    }

    ${({ textContentSide }) =>
      textContentSide === 'right'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}

    ${media.md(css`
      ${({ textContentSide }: TwoImagesContentProps) =>
        textContentSide === 'right'
          ? css`
              left: ${({ theme }) => theme.spacing[12]};
            `
          : css`
              right: ${({ theme }) => theme.spacing[12]};
            `}

      height: 135%;
      width: 53%;
    `)};

    ${props => css`
      background: linear-gradient(
        ${props.color1 || gradients.supportSilver[0]},
        ${props.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

export const ImagePair = styled.div<ImagePairProps>`
  position: absolute;
  z-index: ${zIndex.above};

  &:nth-child(1) {
    top: ${({ theme }) => theme.spacing[7.5]};
    width: 53%;
    height: 125%;
    animation: 3000ms ease-in-out 0s 1 imageSlideInFromTop;

    @keyframes imageSlideInFromTop {
      0% {
        opacity: 0;
        transform: translateY(-60px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${({ textContentSide }) =>
      textContentSide === 'right'
        ? css`
            right: ${({ theme }) => theme.spacing[1.5]};
          `
        : css`
            left: ${({ theme }) => theme.spacing[1.5]};
          `}

    ${media.md(css`
      top: ${({ theme }) => theme.spacing[2.5]};
      width: 38%;
      height: 100%;

      ${({ textContentSide }: ImagePairProps) =>
        textContentSide === 'right'
          ? css`
              right: ${between(24 / modularScale, 80)};
            `
          : css`
              left: ${between(24 / modularScale, 80)};
            `}
    `)};
  }

  &:nth-child(2) {
    top: ${({ theme }) => theme.spacing[11.5]};
    width: 36%;
    height: 84%;
    animation: 3000ms ease-in-out 0s 1 imageSlideInFromVeryBottom;

    ${({ textContentSide }) =>
      textContentSide === 'right'
        ? css`
            left: ${({ theme }) => theme.spacing[1.5]};
          `
        : css`
            right: ${({ theme }) => theme.spacing[1.5]};
          `}

    ${media.md(css`
      top: ${({ theme }) => theme.spacing[4.5]};
      width: 46%;
      height: 100%;

      ${({ textContentSide }: ImagePairProps) =>
        textContentSide === 'right'
          ? css`
              left: 0;
            `
          : css`
              right: 0;
            `}
    `)};
  }
`

export const ThreeImagesContainer = styled.div<ThreeImagesContainerProps>`
  flex: 0 1 100%;
  margin-bottom: ${({ theme }) => theme.spacing[7]};
  position: relative;

  ${mediaMax.md(css`
    ${mixins.breakFromSiteGutter()}
  `)}

  ${media.md(css`
    flex: 0 1 60%;
    margin-top: 0;
    margin-bottom: 0;
    ${({ textContentSide }: ThreeImagesContainerProps) =>
      textContentSide === 'right'
        ? css`
            margin-left: 0;
            order: -1;
          `
        : css`
            margin-right: 0;
            margin-right: ${({ theme }) => theme.spacing[-1.5]};
          `}
  `)};
`

export const ThreeImagesContent = styled.div<ThreeImagesContentProps>`
  position: relative;
  flex: 0 0 100%;
  min-height: 288px;
  z-index: ${zIndex.above};

  ${media.md(css`
    flex: 0 1 50%;
    min-height: ${between(480 / (modularScale * 2), 480)};
    align-self: flex-start;
  `)};

  &::after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.spacing[8]};
    width: 58%;
    height: 115%;
    animation: 3000ms ease-in-out 0s 1 contentSlideInFromBottom;

    .t-dark & {
      opacity: 0.1;
    }

    @keyframes contentSlideInFromBottom {
      0% {
        opacity: 0;
        transform: translateY(54px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${media.md(css`
      left: ${({ theme }) => theme.spacing[8]};
      height: 120%;
      width: 52%;
    `)};

    ${props => css`
      background: linear-gradient(
        ${props.color1 || gradients.supportSilver[0]},
        ${props.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

export const ImageTrio = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: ${zIndex.above};

  &:nth-child(1) {
    z-index: ${zIndex.above2};
    bottom: ${({ theme }) => theme.spacing[-2]};
    left: 0;
    width: 55%;
    height: 72%;
    animation: 3000ms ease-in-out 0s 1 imageSlideInFromVeryTopMobile;

    @keyframes imageSlideInFromVeryTopMobile {
      0% {
        opacity: 0;
        transform: translateY(-60px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${media.md(css`
      bottom: ${({ theme }) => theme.spacing[-2]};
      left: ${({ theme }) => theme.spacing[1.5]};
      width: 52%;
      height: 80%;
      animation: 3000ms ease-in-out 0s 1 imageSlideInFromVeryTop;

      @keyframes imageSlideInFromVeryTop {
        0% {
          opacity: 0;
          transform: translateY(-112px);
        }
        100% {
          opacity: 100%;
          transform: translateY(0);
        }
      }
    `)};

    ${media.mlg(css`
      bottom: ${({ theme }) => theme.spacing[-3]};
    `)};
  }

  &:nth-child(2) {
    top: ${({ theme }) => theme.spacing[3]};
    right: 0;
    width: 53%;
    height: 70%;
    animation: 3000ms ease-in-out 0s 1 imageSlideInFromBottomMobile;

    @keyframes imageSlideInFromBottomMobile {
      0% {
        opacity: 0;
        transform: translateY(35px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${media.md(css`
      top: ${({ theme }) => theme.spacing[1.5]};
      right: ${({ theme }) => theme.spacing[1.5]};
      width: 47%;
      height: 76%;
      animation: 3000ms ease-in-out 0s 1 imageSlideInFromBottom;

      @keyframes imageSlideInFromBottom {
        0% {
          opacity: 0;
          transform: translateY(72px);
        }
        100% {
          opacity: 100%;
          transform: translateY(0);
        }
      }
    `)};

    ${media.mlg(css`
      top: ${({ theme }) => theme.spacing[2.5]};
    `)};
  }

  &:nth-child(3) {
    bottom: ${({ theme }) => theme.spacing[-6]};
    right: ${({ theme }) => theme.spacing[3]};
    width: 31%;
    height: 33%;
    animation: 3000ms ease-in-out 0s 1 imageSlideInFromVeryBottomMobile;

    @keyframes imageSlideInFromVeryBottomMobile {
      0% {
        opacity: 0;
        transform: translateY(30px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }

    ${media.md(css`
      bottom: ${({ theme }) => theme.spacing[-6]};
      right: ${({ theme }) => theme.spacing[4.5]};
      width: 28%;
      height: 36%;
      animation: 3000ms ease-in-out 0s 1 imageSlideInFromVeryBottom;

      @keyframes imageSlideInFromVeryBottom {
        0% {
          opacity: 0;
          transform: translateY(150px);
        }
        100% {
          opacity: 100%;
          transform: translateY(0);
        }
      }
    `)};

    ${media.mlg(css`
      bottom: ${({ theme }) => theme.spacing[-9]};
    `)};
  }
`
