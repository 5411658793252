import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'

import { base, gridOffsetFrom, gridOffsetTo } from '../../constants/sizes'
import { between } from '../../utils/between'
import { media } from '../../utils/media'
import { StyledSection } from './styles'

type SectionProps = {
  spacingFromEdge?: any
  leftOffset?: any
  rightOffset?: any
  fullWidthMobile?: any
}

type BackgroundProps = {
  background?: any
}

const Section = styled(StyledSection)<SectionProps>`
  padding-left: ${({ spacingFromEdge }) =>
    spacingFromEdge && spacingFromEdge.xs && `${spacingFromEdge.xs * base}px`};
  padding-right: ${({ spacingFromEdge }) =>
    spacingFromEdge && spacingFromEdge.xs && `${spacingFromEdge.xs * base}px`};

  ${({ spacingFromEdge }) =>
    spacingFromEdge &&
    media.md(
      map(
        spacingFromEdge,
        val => `padding-left: ${val * base}px; padding-right: ${val * base}px;`
      )
    )}

  ${({ leftOffset, fullWidthMobile }) =>
    leftOffset &&
    css`
      padding-left: ${fullWidthMobile ? '0' : gridOffsetFrom}px;
      ${media.md(
        `padding-left: ${between(gridOffsetFrom, gridOffsetTo)};
          `
      )}
    `}

  ${({ rightOffset, fullWidthMobile }) =>
    rightOffset &&
    css`
      padding-right: ${fullWidthMobile ? '0' : gridOffsetFrom}px;
      ${media.md(
        `padding-right: ${between(gridOffsetFrom, gridOffsetTo)};
          `
      )}
    `}
`

const Background = styled.div<BackgroundProps>`
  ${({ background }) =>
    background &&
    css`
      background: linear-gradient(
        ${get(background, 'fields.degrees', background.degrees)},
        ${get(background, 'fields.color1', background.color1)},
        ${get(background, 'fields.color2', background.color2)}
      );
    `}
`

export const OffsetSection = ({
  children,
  config,
  innerRef = undefined,
  isHeader: defaultIsHeader = undefined,
  top: defaultTop = undefined,
  bottom: defaultBottom = undefined,
  offsetDirection = '',
  fullWidthMobile = false,
  gradient: defaultGradient = undefined,
  background = undefined,
  textColor = undefined,
  spacingFromEdge = { xs: 0, md: 0 },
  hideOverflowX = false,
  ...props
}) => {
  config = config ? config.fields : {}
  const isHeader = config.isHeader || defaultIsHeader
  const top = config.top || defaultTop
  const bottom = config.bottom || defaultBottom
  const gradient = config.gradient || defaultGradient
  const backgroundGradient = config.background || background
  const color = config.textColor || textColor
  const id = props.id || config.id

  const offsets = {
    leftOffset: offsetDirection === 'left' || offsetDirection === 'both',
    rightOffset: offsetDirection === 'right' || offsetDirection === 'both',
  }

  return (
    <Section
      isHeader={isHeader}
      top={top}
      bottom={bottom}
      gradient={gradient}
      {...offsets}
      fullWidthMobile={fullWidthMobile}
      noHorizontalPadding
      spacingFromEdge={spacingFromEdge}
      textColor={color}
      ref={innerRef}
      id={id}
      hideOverflowX={hideOverflowX}
      {...props}
    >
      <Background background={backgroundGradient}>{children}</Background>
    </Section>
  )
}

OffsetSection.propTypes = {
  bottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  gradient: PropTypes.object,
  gradientOffset: PropTypes.number,
  gradientStartsWhite: PropTypes.bool,
  isHeader: PropTypes.bool,

  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  id: PropTypes.string,
  hideOverflowX: PropTypes.bool,
}

OffsetSection.defaultProps = {
  bottom: { xs: 4, md: 9 },
  top: { xs: 4, md: 9 },
  gradient: {
    fields: {
      color1: 'transparent',
      color2: 'transparent',
      degrees: '90deg',
    },
  },
}
