import {
  formatButton,
  formatGradient,
  formatListItems,
  formatPriceRef,
} from './elements'

export const formatHeroOfferCard = heroOfferCard =>
  heroOfferCard && {
    id: heroOfferCard.sys.id,
    type: heroOfferCard.sys.contentType.sys.id,
    title: heroOfferCard.fields.title,
    text: heroOfferCard.fields.text,
    button:
      heroOfferCard.fields.button && formatButton(heroOfferCard.fields.button),
  }

export const formatCoverCard = coverCard =>
  coverCard && {
    id: coverCard.sys.id,
    type: coverCard.sys.contentType.sys.id,
    title: coverCard.fields.title,
    subtitle: coverCard.fields.multiLineSubtitle,
    height: coverCard.fields.height,
    media: coverCard.fields.media,
    fallbackMedia: coverCard.fields.fallbackMedia,
    gradientOverlay: coverCard.fields.gradientOverlay,
    grayscale: coverCard.fields.grayscale,
    link: coverCard.fields.link,
  }

export const formatComparisonCard = comparisonCard =>
  comparisonCard && {
    id: comparisonCard.sys.id,
    type: comparisonCard.sys.contentType.sys.id,
    title: comparisonCard.fields.title,
    name: comparisonCard.fields.name,
    image: comparisonCard.fields.image,
    subtitle: comparisonCard.fields.subtitle,
    items:
      comparisonCard.fields.listItems &&
      formatListItems(comparisonCard.fields.listItems),
    isRetreat: comparisonCard.fields.isRetreat,
    link: comparisonCard.fields.link,
    buttonText: comparisonCard.fields.buttonText,
    text: comparisonCard.fields.text,
    textColor: comparisonCard.fields.textColor,
    backgroundImage: comparisonCard.fields.backgroundImage,
    backgroundGradient:
      comparisonCard.fields.backgroundGradient &&
      formatGradient(comparisonCard.fields.backgroundGradient),
    priceRef:
      comparisonCard.fields.priceRef &&
      formatPriceRef(comparisonCard.fields.priceRef),
    priceFormat: comparisonCard.fields.priceFormat,
  }

export const formatCard = card =>
  card && {
    id: card.sys.id,
    type: card.sys.contentType.sys.id,
    title: card.fields.title,
    description: card.fields.description,
    image: card.fields.image,
    gradient: card.fields.gradient && formatGradient(card.fields.gradient),
    footNote: card.fields.footNote,
    link: card.fields.link,
    priceRef:
      card.fields.priceRef && formatPriceRef(card.fields.priceReference),
    priceFormat: card.fields.priceFormat,
  }

export const formatSuiteCard = card =>
  card && {
    id: card.sys.id,
    type: card.sys.contentType.sys.id,
    title: card.fields.title,
    description: card.fields.description,
    image: card.fields.image,
    gradient: card.fields.gradient && formatGradient(card.fields.gradient),
    footNote: card.fields.footNote,
    link: card.fields.link,
    priceRef: card.fields.priceRef && formatPriceRef(card.fields.priceRef),
    priceFormat: card.fields.priceFormat,
  }

export const formatGradientOffsetCard = gradientOffsetCard =>
  gradientOffsetCard && {
    id: gradientOffsetCard.sys.id,
    type: gradientOffsetCard.sys.contentType.sys.id,
    tagline: gradientOffsetCard.fields.tagline,
    heading: gradientOffsetCard.fields.heading,
    subheading: gradientOffsetCard.fields.subheading,
    text: gradientOffsetCard.fields.text,
    textContent: gradientOffsetCard.fields.textContent,
    image: gradientOffsetCard.fields.image,
    imageLayout: gradientOffsetCard.fields.imageLayout,
    ctaLabel: gradientOffsetCard.fields.ctaLabel,
    ctaUrl: gradientOffsetCard.fields.ctaUrl,
    button:
      gradientOffsetCard.fields.button &&
      formatButton(gradientOffsetCard.fields.button),
    gradient:
      gradientOffsetCard.fields.gradient &&
      formatGradient(gradientOffsetCard.fields.gradient),
    offsetX: gradientOffsetCard.fields.offsetX,
    offsetY: gradientOffsetCard.fields.offsetY,
    smallOffset: gradientOffsetCard.fields.smallOffset,
    smallPadding: gradientOffsetCard.fields.smallPadding,
    elevated: gradientOffsetCard.fields.elevated,
    textAlign: gradientOffsetCard.fields.textAlign,
    backgroundColor: gradientOffsetCard.fields.backgroundColor,
    price: gradientOffsetCard.fields.price,
  }

export const formatImageCard = imageCard =>
  imageCard && {
    id: imageCard.sys.id,
    type: imageCard.sys.contentType.sys.id,
    image: imageCard.fields.image,
    lightBoxImage: imageCard.fields.lightBoxImage,
    imageSize: imageCard.fields.imageHeight,
    backgroundPosition: imageCard.fields.backgroundPosition,
  }

export const formatCopyCard = copyCard =>
  copyCard && {
    id: copyCard.sys.id,
    type: copyCard.sys.contentType.sys.id,
    heading: copyCard.fields.heading,
    textContent: copyCard.fields.textContent,
    subtext: copyCard.fields.subtext,
    backgroundColor: copyCard.fields.backgroundColor,
    color: copyCard.fields.color,
    image: copyCard.fields.image,
    alignText: copyCard.fields.alignText,
  }

export const formatPackageCard = packageCard =>
  packageCard && {
    id: packageCard.sys.id,
    type: packageCard.sys.contentType.sys.id,
    tagline: packageCard.fields.tagline,
    title: packageCard.fields.title,
    description: packageCard.fields.description,
    color: packageCard.fields.color,
    footerColor: packageCard.fields.footerColor,
    image: packageCard.fields.image,
    backgroundPosition: packageCard.fields.backgroundPosition,
    primaryCtaTitle: packageCard.fields.primaryCtaTitle,
    primaryCtaUrl: packageCard.fields.primaryCtaUrl,
    secondaryCtaTitle: packageCard.fields.secondaryCtaTitle,
    secondaryCtaUrl: packageCard.fields.secondaryCtaUrl,
    priceRef:
      packageCard.fields.priceRef &&
      formatPriceRef(packageCard.fields.priceRef),
    priceFormat: packageCard.fields.priceFormat,
    gradientOverlay:
      packageCard.fields.gradientOverlay &&
      formatGradient(packageCard.fields.gradientOverlay),
  }

export const formatUpgradeCard = upgradeCard =>
  upgradeCard && {
    id: upgradeCard.sys.id,
    type: upgradeCard.sys.contentType.sys.id,
    title: upgradeCard.fields.title,
    url: upgradeCard.fields.url || 'lol',
    description: upgradeCard.fields.description,
    image: upgradeCard.fields.image,
    priceRef:
      upgradeCard.fields.priceRef &&
      formatPriceRef(upgradeCard.fields.priceRef),
    priceFormat: upgradeCard.fields.priceFormat,
  }

export const formatCollectionCard = card =>
  card && {
    id: card.sys.id,
    title: card.fields.title,
    price: card.fields.price,
    description: card.fields.description,
    link: card.fields.link ? card.fields.link.fields.url : undefined,
    linkLabel: card.fields.link ? card.fields.link.fields.label : undefined,
    image: card.fields.image,
  }

export const formatStoryCard = story =>
  story && {
    id: story.sys.id,
    title: story.fields.title,
    date: story.fields.publishDate,
    topic: story.fields.topic,
    image: story.fields.heroImage.fields.image,
    imageRatio: story.imageRatio,
    link: story.fields.slug,
    moreStoriesAnalyticsEvent: story.fields.moreStoriesAnalyticsEvent,
    isSmall: story.fields.isSmall,
    withDate: story.withDate,
  }

export default {
  collectionCard: formatCollectionCard,
  amenityCard: formatCoverCard,
  comparisonCard: formatComparisonCard,
  splitCard: formatCard,
  imageCard: formatImageCard,
  copyCard: formatCopyCard,
  packageCard: formatPackageCard,
  suiteCard: formatSuiteCard,
  gradientOffsetCard: formatGradientOffsetCard,
  pageStory: formatStoryCard,
}
